import React, { Component } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 1rem;
  background-color: ${p => p.theme.color.white10};
  margin-bottom: 1rem;
`;

const FrameContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

class VideoPlayer extends Component {
  render() {
    const { src } = this.props;
    return (
      <Container>
        <FrameContainer>
          <iframe
            title="Youtube"
            src={src}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen />
        </FrameContainer>
      </Container>
    )
  }
}

export default VideoPlayer;
