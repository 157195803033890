import React, { useState, createContext } from 'react';

export const ChatContext = createContext(null);

const ChatProvider = ({ children }) => {
  const [chatLog, setChatLog] = useState([]);

  return (
    <ChatContext.Provider value={{log: chatLog, setLog: setChatLog}}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
