import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Link = ({ to, localize = true, newTab = false, ...props }) => {
  const { i18n } = useTranslation();
  
  let langRoute = '';
  if (localize) {
    langRoute = i18n.language === 'fi' ? '' : `/${i18n.language}`;
  }

  return (
    <ReactLink
      to={`${langRoute}${to}`}
      {...props}
      target={newTab ? "_blank" : undefined}
    />
  );
};

export default Link;