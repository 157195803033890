import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

const Title = styled.h1`
  display: block;
  color: ${p => p.theme.color.redDark};
  font-size: 2.5rem;
  text-align: center;
  padding-top: 0;
  margin-bottom: 1.5rem;

  @media (max-width: 799px) {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 639px) {
    font-size: 1.8rem;
    margin-bottom: 0.85rem;
  }
  @media (max-width: 479px) {
    font-size: 1.6rem;
    margin-bottom: 0.7rem;
  }
  @media (max-width: 399px) {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }
`;

const CountdownContainer = styled.div`
  margin-bottom: 1rem;

  &.infoscreen {
    margin-left: -2rem;
    margin-right: -2rem;
  }
`;

const DigitContainer = styled.div`
  display: flex;
  justify-content: space-evenly;

  .infoscreen & {
    justify-content: space-between;
  }
`;

const DigitBlock = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  column-gap: 0.8rem;
  row-gap: 0.5rem;
  justify-items: center;

  @media (max-width: 799px) {
    column-gap: 0.6rem;
    row-gap: 0.4rem;
  }
  @media (max-width: 639px) {
    column-gap: 0.5rem;
    row-gap: 0.3rem;
  }
  @media (max-width: 479px) {
    column-gap: 0.4rem;
    row-gap: 0.25rem;
  }
  @media (max-width: 399px) {
    column-gap: 0.3rem;
    row-gap: 0.2rem;
  }

  .infoscreen & {
    column-gap: 1rem;
    row-gap: 1rem;
  }
`;

const DigitSubtitle = styled.div`
  grid-column: span 2;
  font-size: 1.6rem;
  font-weight: 600;

  @media (max-width: 999px) {
    font-size: 1.5rem;
  }
  @media (max-width: 799px) {
    font-size: 1.2rem;
  }
  @media (max-width: 639px) {
    font-size: 1rem;
  }
  @media (max-width: 479px) {
    font-size: 0.9rem;
  }
  @media (max-width: 399px) {
    font-size: 0.8rem;
  }

  .infoscreen & {
    font-size: 1.8rem;
  }
`;

const DigitWrapper = styled.div`
  background-color: ${p => p.theme.color.countdownBg};
  font-size: 6rem;
  height: 1.2em;
  width: 1.5ch;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  
  @media (max-width: 999px) {
    font-size: 5rem;
  }
  @media (max-width: 799px) {
    font-size: 4rem;
  }
  @media (max-width: 639px) {
    font-size: 3rem;
  }
  @media (max-width: 479px) {
    font-size: 2.4rem;
  }
  @media (max-width: 399px) {
    font-size: 2.1rem;
  }

  .infoscreen & {
    font-size: 10rem;
    width: 1.2ch;
  }
`;

const Digit = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;

  &.countdown-enter {
    opacity: 0;
    top: -100%;
  }
  &.countdown-enter-active {
    opacity: 1;
    top: 0;
    transition: opacity 300ms, top 300ms;
  }
  &.countdown-exit {
    opacity: 1;
    top: 0;
  }
  &.countdown-exit-active {
    opacity: 0;
    top: 100%;
    transition: opacity 300ms, top 300ms;
  }
`;

const Countdown = ({ title, to, infoScreen }) => {
  const { t } = useTranslation();
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    let lastTick = Date.now();
    let timer;
    const tick = () => {
      setNow(Date.now());
      // Tick semi-consistently every second
      const nextTick = lastTick + 1000;
      timer = setTimeout(tick, Math.max(0, nextTick - Date.now()));
      lastTick = nextTick;
    };
    timer = setTimeout(tick, 1000);
    return () => clearInterval(timer);
  }, []);

  const totalMs = Math.max(to - now, 0);
  const sec = Math.floor(totalMs / 1e3 % 60);
  const min = Math.floor(totalMs / 60e3 % 60);
  const hour = Math.floor(totalMs / 3600e3 % 24);
  const day = Math.floor(totalMs / 86400e3);

  const renderDigit = (digit) => (
    <DigitWrapper>
      <TransitionGroup>
        <CSSTransition key={digit} classNames="countdown" timeout={300}>
          <Digit>{digit}</Digit>
        </CSSTransition>
      </TransitionGroup>
    </DigitWrapper>
  );

  const renderDigits = (number, subtitle) => (
    <DigitBlock>
      {renderDigit(Math.floor(number / 10))}
      {renderDigit(number % 10)}
      <DigitSubtitle>{subtitle}</DigitSubtitle>
    </DigitBlock>
  );

  return (
    <CountdownContainer className={infoScreen ? "infoscreen" : ""}>
      {title && <Title>{title}</Title>}
      <DigitContainer>
        {renderDigits(day, t("countdown.days"))}
        {renderDigits(hour, t("countdown.hours"))}
        {renderDigits(min, t("countdown.minutes"))}
        {renderDigits(sec, t("countdown.seconds"))}
      </DigitContainer>
    </CountdownContainer>
  );
}

export default Countdown;
