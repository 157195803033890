import React, { Component } from 'react';
import styled from 'styled-components';
import i18next from '../../../i18n';

const Container = styled.div`
`;

const DurationInput = styled.input`
  padding: 0.5rem;
  margin-left: 1rem;
`;

const InputLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Subtitle = styled.h4`
`;

const AmountContainer = styled.div`
  padding: 1rem 2rem;
`;

const Row = styled.div`
  line-height: 1.5rem;
`;

class Programme extends Component {
  state = {
    duration: 1,
  }

  onInputChange = evt => {
    this.setState({
      duration: evt.target.value,
    });
  }

  calculateAmounts = hours => {
    const t = i18next.t;
    const calc = perc => {
      const num = perc * hours;
      const wholes = Math.floor(num);
      const decimals = num - wholes;
      const mins = Math.floor(decimals * 60);

      const hourPart = wholes > 0 ? t('calculator.hour', { count: wholes }) : '';
      const minutePart = mins > 0 ? t('calculator.minute', { count: mins }) : '';
      return `${hourPart} ${minutePart}`;
    };

    return {
      ads: calc(0.05),
      speech: calc(0.50),
      music: calc(0.35),
      jingles: calc(0.10),
    }
  }

  render() {
    const { duration } = this.state;
    const amounts = this.calculateAmounts(Math.abs(duration));
    const t = i18next.t;
    return (
      <Container id="calculator">
        <h3>{t('calculator.title')}</h3>
        <p>{t('calculator.description')}</p>
        <InputLabel>
          {t('calculator.input')}
          <DurationInput
            type="number"
            value={duration}
            onChange={this.onInputChange}
            min={0}
          />
        </InputLabel>

        <Subtitle>{t('calculator.structure')}</Subtitle>
        <AmountContainer>
          <Row>{t('calculator.talk')}: {amounts.speech}</Row>
          <Row>{t('calculator.music')}: {amounts.music}</Row>
          <Row>{t('calculator.jingles')}: {amounts.jingles}</Row>
          <Row>{t('calculator.ads')}: {amounts.ads}</Row>
        </AmountContainer>
      </Container>
    );
  }
}

export default Programme;
