import React from 'react';
import styled from 'styled-components';
import { Route, Redirect, Switch, Link as ReactLink } from 'react-router-dom';
import FAQ from './FAQ';
import Checklist from './Checklist';
import { useTranslation } from 'react-i18next';

const Link = styled(ReactLink)`
  margin-right: 1rem;
`;

const Main = styled.main`
  background-color: ${p => p.theme.color.containerBg};
  padding: 1rem;
`;

const Guide = ({ match }) => {
  const { t } = useTranslation();
  return (
    <Main>
      <h1>{t('guide')}</h1>
      <nav>
        <Link to={`${match.url}/checklist`}>{t('checklist.title')}</Link>
        <Link to={`${match.url}/faq`}>{t('faq.title')}</Link>
      </nav>
      <Switch>
        <Route path={`${match.url}/checklist`} component={Checklist} />
        <Route path={`${match.url}/faq`} component={FAQ} />
        <Redirect to={`${match.url}/checklist`} />
      </Switch>
    </Main>
  );
}

export default Guide;
