import React from 'react';
import styled from 'styled-components';
import { CalendarIcon } from '../Icons';
import { useTranslation } from 'react-i18next';

const Widget = styled.span`
  display: flex;
  align-items: center;

  margin: 0.5rem 0;
  user-select: none;
  font-variant-numeric: tabular-nums;
  cursor: pointer;

  & > * {
    margin-right: 1em;
  }

  &:hover {
    color: ${p => p.theme.color.redDark};
  }

  svg {
    color: ${p => p.theme.color.redDark};
    width: 1em;
    height: 1em;
  }
`;

const DatePicker = ({ date, onClick, show }) => {
  const { t } = useTranslation();
  return (
    <Widget onClick={onClick}>
      <CalendarIcon />
      <span>{date || t('signup.propositions.date')}</span>
      {show &&  <div><i className="fas fa-chevron-up"></i></div>}
      {!show && <div><i className="fas fa-chevron-down"></i></div>}
    </Widget>
  );
}

export default DatePicker;
