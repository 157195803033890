import React, { Component, Suspense } from 'react';

import Routes from './routes/Routes';
import SocketProvider from './common/SocketProvider';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle, theme } from '../theme';
// import { environment } from '../utils';

// console.log('environment:', environment);

class App extends Component {
  render() {
    return (
      <Suspense fallback={<div></div>}>
        <ThemeProvider theme={theme}>
          <SocketProvider>
            <Routes />
          </SocketProvider>
          <GlobalStyle />
        </ThemeProvider>
      </Suspense>
    );
  }
}

export default App;
