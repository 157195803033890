import React, { Component } from 'react';
import styled from 'styled-components';

import { parseSearch } from '../../../utils';
import i18next from '../../../i18n';
import { withTranslation } from 'react-i18next';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Paragraph = styled.div`
  max-width: 700px;
  margin-bottom: 1rem;
  flex-grow: 1;

  hr {
    width: 50%;
    text-align: left;
  }
`;

const Title = styled.h3`
  margin: 0;
  height: 4rem;
`;

class RecruitmentSuccess extends Component {
  render() {
    const { location } = this.props;
    const { search } = location;
    const queryParams = parseSearch(search);
    const { email } = queryParams;
    const t = i18next.t;

    return (
      <Container>
        <Paragraph>
          <Title>{t('signup.success.title')}</Title>
          <Paragraph>
            {t('rekry.success.description')}
          </Paragraph>
          { email && (
          <Paragraph>
            {t('rekry.success.email')} { email }.
          </Paragraph>
          )}
        </Paragraph>
      </Container>
    );
  }
}

export default withTranslation()(RecruitmentSuccess);
