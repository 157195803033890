import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Link from '../../common/Link';

const Container = styled.div`
`;

const Question = styled.i`
  &::before {
    content: 'Q: ';
  }

  display: block;
  margin: 1rem 0 0.5rem;
`;

const Answer = styled.div`
  margin: 1rem 0 3rem 1.4rem;
`;

const EmailLink = ({ children }) => <a href={`mailto:${children}`}>{children}</a>;

// Stuff that can be interpolated into translation strings
const faqComponents = {
  email: <EmailLink />,
  frontpage: <Link to="/" />,
  library: <Link to="/library" />,

  m3u: <a href="https://static.radiodiodi.fi/radiodiodi.m3u" download>radiodiodi.m3u</a>,
  flac: <a href="https://virta.radiodiodi.fi/flac">https://virta.radiodiodi.fi/flac</a>,
  mp3: <a href="https://virta.radiodiodi.fi/mp3">https://virta.radiodiodi.fi/mp3</a>,
  aac: <a href="https://virta.radiodiodi.fi/aac">https://virta.radiodiodi.fi/aac</a>,
  fb2k: <a href="https://www.foobar2000.org/">Foobar2000</a>,
  vlc: <a href="https://www.videolan.org/">VLC</a>,
};

const qas = [
  {
    question: 'faq.what.q',
    answer: 'faq.what.a',
  },
  {
    question: 'faq.frequency.q',
    answer: 'faq.frequency.a',
  },
  {
    question: 'faq.streams.q',
    answer: 'faq.streams.a',
  },
  {
    question: 'faq.when.q',
    answer: 'faq.when.a',
  },
  {
    question: 'faq.where.q',
    answer: 'faq.where.a',
  },
  {
    question: 'faq.who.q',
    answer: 'faq.who.a',
  },
  {
    question: 'faq.idea.q',
    answer: 'faq.idea.a',
  },
  // , jotta toimitus voi valmistella studion erikoisempaa ohjelmaa varten. Autamme mielellämme typerienkin ideoidesi toteuttamisessa
  {
    question: 'faq.howMany.q',
    answer: 'faq.howMany.a',
  },
  {
    question: 'faq.music.q',
    answer: 'faq.music.a',
  },
  {
    question: 'faq.notAllowed.q',
    answer: 'faq.notAllowed.a',
  },
  {
    question: 'faq.tech.q',
    answer: 'faq.tech.a',
  },
  // {
  //   question: 'Saako studiossa syödä/juoda? Entä alkoholi?',
  //   answer: 'Vältä syömistä studiotiloissa. Alkoholi on kielletty sillä lähetys tapahtuu koulun tiloista.'
  //   // answer: 'Kunhan laitteet pysyvät ehjänä ja kunnossa, mikään ei estä studiossa syömistä tai juomista.'
  // },
  {
    question: 'faq.ads.q',
    answer: 'faq.ads.a',
  },
  {
    question: 'faq.jingle.q',
    answer: 'faq.jingle.a',
  },
  {
    question: 'faq.ownJingle.q',
    answer: 'faq.ownJingle.a',
  },
  {
    question: 'faq.record.q',
    answer: 'faq.record.a',
  },
  {
    question: 'faq.prerecord.q',
    answer: 'faq.prerecord.a',
  },
  {
    question: 'faq.remote.q',
    answer: 'faq.remote.a',
  },
  {
    question: 'faq.producer.q',
    answer: 'faq.producer.a',
  },
  {
    question: 'faq.producing.q',
    answer: 'faq.producing.a',
    // answer: 'Laita sähköpostia <a href="mailto:studio@radiodiodi.fi">studio@radiodiodi.fi</a> ja liity Radiodiodin Telegram-kanaville. Tuottajakoulutuksia järjestetään kiinnostuneille studion rakennusviikolla ja ensimmäisen lähetysviikon aikana.'
  },
  {
    question: 'faq.needProducer.q',
    answer: 'faq.needProducer.a',
  },
  {
    question: 'faq.rekry.q',
    answer: 'faq.rekry.a',
  }
];

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <h2>{t('faq.title')}</h2>
      <h3>{t('faq.facts.title')}</h3>
      <ul>
        <li>{t('faq.facts.laws')}</li>
        <li>{t('faq.facts.editor')}</li>
        <li>{t('faq.facts.finance')}</li>
        {/* <li>Radio-ohjelman tuottaa Radiodiodin tuottaja tai koulutettu ohjelmantekijä.</li> */}
        <li>{t('faq.facts.fun')}</li>
        <li>{t('faq.facts.experience')}</li>
      </ul>
      <br />
      {qas.map((qa, index) => (
        <Fragment key={index}>
          <Question>
            <Trans i18nKey={qa.question} components={faqComponents} />
          </Question>
          <Answer>
            <Trans i18nKey={qa.answer} components={faqComponents} />
          </Answer>
        </Fragment>
      ))}
    </Container>
  );
}

export default FAQ;
