import React from 'react';
import styled from 'styled-components';
import { environment } from '../../utils';

const tek = `${environment.REACT_APP_STATIC_URL}/img/2022/sponsors/tek.png`;
// const siili = `${environment.REACT_APP_STATIC_URL}/img/2022/sponsors/Siili_white.png`;
// const knowit = `${environment.REACT_APP_STATIC_URL}/img/2022/sponsors/knowit.png`;
// const granlund = `${environment.REACT_APP_STATIC_URL}/img/2023/sponsors/granlund_white.png`;
const nitor = `${environment.REACT_APP_STATIC_URL}/img/2023/sponsors/nitor_white.svg`;
const fonum = `${environment.REACT_APP_STATIC_URL}/img/2024/sponsors/fonum_white.png`;
const tter = `${environment.REACT_APP_STATIC_URL}/img/2019/TTER.png`;
const wibax = `${environment.REACT_APP_STATIC_URL}/img/2024/sponsors/wibax-logo-white-cmyk.svg`;
const avp = `${environment.REACT_APP_STATIC_URL}/img/2024/sponsors/AVP_Black kopio.svg`;


const SponsorContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: flex-start;
  align-items: center;
  margin: 1rem 0 0 -2rem;

  & > * {
    margin: 0 0 2rem 2rem;
    height: 80px;
    width: 160px;

    ${props => props.medium && `
      height: 150px;
      width: 300px;
    `}

    ${props => props.semilarge && `
      height: 200px;
      width: 400px;
    `}

    ${props => props.large && `
      height: 250px;
      width: 500px;
    `}

    ${props => props.xl && `
      height: 400px;
      width: 750px;
    `}

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;

const SponsorImg = styled.img`
  ${p => p.invert && `
    filter: invert(100%);
  `}
`;

const Sponsor = ({ src, alt, invert }) => (
  <div>
    <SponsorImg src={src} alt={alt} invert={invert} loading="lazy" />
  </div>
);

export const SponsorPyramid = () => {
  return (
    <div>
      {/* <SponsorContainer large>
        <Sponsor src={siili} alt="Siili" />
      </SponsorContainer> */}
      <SponsorContainer semilarge>
        <Sponsor src={tek} alt="TEK" invert />
        <Sponsor src={nitor} alt="Nitor" invert />
        <Sponsor src={fonum} alt="Fonum" invert />
        {/* <Sponsor src={knowit} alt="Knowit" /> */}
        {/* <Sponsor src={granlund} alt="Granlund" /> */}
      </SponsorContainer>
      <SponsorContainer>
        <Sponsor src={tter} alt="TTER" />
        <Sponsor src={wibax} alt="Wibax" invert />
        <Sponsor src={avp} alt="Aalto Ventures Program" />
      </SponsorContainer>
    </div>
  )
}

export default SponsorPyramid;
