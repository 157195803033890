import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { environment } from '../../utils';
import InfoScreenProgram from './InfoScreenProgram';

const { 
  REACT_APP_BACKEND_HTTP_URL,
  REACT_APP_START_DATE,
  REACT_APP_END_DATE
} = environment;


const Title = styled.h1`
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  margin: 0 0 2rem;
`;

const startDate = new Date(REACT_APP_START_DATE)
const endDate = new Date(REACT_APP_END_DATE)

const preloadImages = (images) => {
  images.forEach((src) => {
    const img = document.createElement('img');
    img.src = src; // Assigning the img src immediately requests the image
  });
}

const InfoScreenCalendar = ({ count = 5 }) =>  {
  const [all, setAll] = useState();
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    const timer = setInterval(() => setNow(Date.now()), 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect((() => {
    if (Date.parse(REACT_APP_START_DATE) && Date.parse(REACT_APP_END_DATE)) {
      const fetchProgrammes = async () => {
        try {
          const resp = await fetch(`${REACT_APP_BACKEND_HTTP_URL}/programmes`)
          const data = await resp.json();
          if (!data || !Array.isArray(data)) {
            console.error('Programme data invalid. Data: ', data);
            return;
          }

          data.forEach(program => {
            program.start = new Date(program.start);
            program.end = new Date(program.end);

            // Hotfix for removing html from descriptions
            if (/<br>|<span>/.test(`${program.image}${program.description}${program.team}${program.genre}`)) {
              function removeHtml(text) {
                const div = document.createElement("div");
                div.innerHTML = text;
                return div.textContent.trim();
              }
              if (program.image) program.image = removeHtml(program.image);
              if (program.description) program.description = removeHtml(program.description);
              if (program.team) program.team = removeHtml(program.team);
              if (program.genre) program.genre = removeHtml(program.genre);
            }
          })

          const images = data.map(p => p.image || '');
          preloadImages(images);

          const filtered = data.filter(p => p.start >= startDate && p.end <= endDate)
          const sorted = filtered.sort((x, y) => + x.start - y.start);
          setAll(sorted);
        } catch (error) {
          console.error(error);
        }
      }
      fetchProgrammes();
    }
  }), []);

  const upcoming = useMemo(
    () => all && all.filter(p => p.end > now).slice(0, count),
    [all, now, count],
  );

  if (!all) return null;

  const content = upcoming && upcoming.map(p => <InfoScreenProgram p={p} key={p.id || p.start} />);

  return (
    <div>
      <Title>
        <div>Tulevia radio-ohjelmia</div>
        <div>Upcoming programmes</div>
      </Title>
      {content}
    </div>
  )
}

export default InfoScreenCalendar
