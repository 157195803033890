import React, { useState, useEffect, createContext } from 'react';
import { environment } from '../../utils';

const WS_URL = environment.REACT_APP_BACKEND_WS_URL;

export const SocketContext = createContext(null);

const SocketProvider = ({ children }) => {
  const [connection, setConnection] = useState(() => new WebSocket(WS_URL));

  const reconnect = () => {
    console.log("Reconnecting WebSocket...");
    setConnection(new WebSocket(WS_URL));
  };

  useEffect(() => {
    connection.onopen = () => {
      console.log('WebSocket connection established');
    }
    connection.onerror = (evt) => {
      console.log(`WebSocket error. Data: ${evt.data}`);
    }
    connection.onclose = () => setTimeout(reconnect, 1000);
    return () => { connection.close(); };
  }, [connection]);

  return (
    <SocketContext.Provider value={connection}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
