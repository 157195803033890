import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FadeImage from '../FadeImage';
import PostContainer from './ImageContainer';

const Card = styled.figure`
  /* --card-hover-color: #B03579; */
  --card-hover-color: ${p => p.theme.color.galleryHoverBg};

  align-self: flex-start;
  margin: 1rem;
  margin-left: ${p => p.error ? 'auto' : ''};
  margin-right: ${p => p.error ? 'auto' : ''};
  border-top: 4px solid ${p => p.theme.color.redDark};
  position: relative;
  padding-bottom: 5rem;

  width: 100%;
  padding-top: 93%;

  @media screen and (min-width: 600px) {
    width: calc((100% - 4rem) / 2);
    padding-top: calc((100% - 4rem) / 2);
  }

  @media screen and (min-width: 1000px) {
    width: calc((100% - 6rem) / 3);
    padding-top: calc((100% - 6rem) / 3);
  }

  &:hover,
  &:hover figcaption:after,
  &:focus-within,
  &:focus-within figcaption:after {
    background-color: var(--card-hover-color);
    box-shadow: 0 0 0 1rem var(--card-hover-color);
    color: ${p => p.theme.color.galleryHoverText};
  }

  &:hover figcaption,
  &:focus-within figcaption {
    /* Just something bigger than the text will ever be. Transition does not work for fit-content or anything not exactly a number */
    max-height: 2000px;
    /* transition: max-height 1s ease-in-out; */
    z-index: 10;
    overflow: unset;


    /* hide the ... */
    &:before {
      content: '';
    }

    &:after {
      content: '';
      height: inherit;
      width: inherit;

      margin: 0;

      z-index: -1;
      position: absolute;
      top: 6.45em;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }

  a {
    text-decoration: none;
    color: ${p => p.theme.color.white100};

    &:hover {
      text-decoration: none;
    }
  }
`;

const Image = styled(FadeImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: contain;
  background-color: black;

  animation: 0.5s fadeIn;
  object-fit: contain;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const ImageContainer = styled.div`
  width: 100%;
  padding-bottom: 100%;
  height: 0;
  position: absolute;
  top: 0;
`
const ImageCarouselContainer = styled(ImageContainer)`
  .fa-images {
    pointer-events: none;
    z-index: 1;
    position: absolute;
    top: 5%;
    right: 5%;

    opacity: 0.8;
    font-size: 2rem;
  }

  .arrow-container {
    z-index: 10;
    position: absolute;
    top: 50%;
    opacity: 0.2;
    color: white;
    border: none;
    padding: 0 0.5rem;
    font-size: 3rem;
  }

  &:hover .arrow-container {
    transition: opacity 200ms;
    opacity: 1;
  }

  .arrow-container:hover,
  .arrow-container:focus {
    color: ${p => p.theme.color.redDark};
    opacity: 0.9;
    cursor: pointer;
  }

  .arrow-container.disabled:hover,
  .arrow-container.disabled:focus {
    color: white;
    cursor: default;
  }

  .arrow-container.disabled {
    opacity: 0.2;
  }

  .next {
    right: 0;
  }

  .prev {
    left: 0;
  }

  &:focus-within .page-indicator,
  &:hover .page-indicator {
    opacity: 1;
  }
`;

const PageIndicatorContainer = styled.span`
  pointer-events: none;
  display: flex;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;

  margin: 0 auto;
  width: min-content;

  z-index: 1;
  opacity: 0;

  transition: opacity 200ms;

  div {
    opacity: 0.4;
    height: 0.7rem;
    width: 0.7rem;
    background-color: white;
    margin: 0.3rem;
    border-radius: 100%;
  }

  div.active {
    opacity: 1;
  }
`

const Caption = styled.figcaption`
  width: 100%;
  margin-top: 0.5em;
  overflow: hidden;

  /*
  custom three dots for multiline component
  hide text if it more than N lines
  use this value to count block height
  */

  position: absolute;

  /* max-height = line-height (1.5) * lines max number (3) */
  line-height: 1.5;
  max-height: 4.5em;

  /* transition: max-height .5s cubic-bezier(0, 1, 0, 1); */

  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;

  /* place for '...' */
  margin-right: -1em;
  padding-right: 1em;

  /* create the ... */
  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  /* hide ... if we have text, which is less than or equal to max lines */
  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: ${p => p.theme.color.bgGreen};
  }
`;

const PageIndicator = ({index, count}) => {

  const dots = [];
  for (let i = 0; i < count; i++) {
    const dot = <div key={i} className={i === index ? "active" : ""} />
    dots.push(dot);
  }

  return (
   <PageIndicatorContainer className="page-indicator">
     {dots}
   </PageIndicatorContainer>
  )
}

const renderMedia = (obj) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={obj.permalink}>
      {obj.media_type === "VIDEO"
      ? <Video controls>
          <source src={obj.media_url} type="video/mp4" />
        </Video>
      : <Image alt="Instagram-kuva" src={obj.media_url} />
      }
    </a>
  )
}

const ImageCarousel = ({mediaObj}) => {
  const [index, setIndex] = useState(0);
  const count = mediaObj.children.data.length
  const slides = mediaObj.children.data.map(child => renderMedia(child));

  const prev = () => {
    setIndex(Math.max(index -1, 0) )
  }

  const next = () => {
    setIndex(Math.min(index + 1, count - 1) )
  }

  const ArrowLeft = () => (
    <span className={"arrow-container prev" + (index === 0 ? " disabled" : "")} onClick={prev}><i className="fas fa-chevron-left"></i></span>
  )

  const ArrowRight = () => (
    <span className={"arrow-container next" + (index >= count - 1 ? " disabled" : "")} onClick={next}><i className="fas fa-chevron-right"></i></span>
    )

  return (
    <ImageCarouselContainer>
      <i className="far fa-images"></i>
      {slides[index]}
      <ArrowLeft />
      <ArrowRight />
      <PageIndicator index={index} count={count} />
    </ImageCarouselContainer>
  );
}

const renderCard = (obj) => {
  return (
    <Card key={obj.id}>
          {obj.media_type === "CAROUSEL_ALBUM"
          ? <ImageCarousel mediaObj={obj} />
          : <ImageContainer>
              {renderMedia(obj)}
            </ImageContainer>
          }
      <Caption>
        {obj.caption}
      </Caption>
    </Card>
  );
}

const InstagramGallery = ({objs}) => {
  const images = objs.map(renderCard)
  return (
    <>
      <h2>#Instagram-feed</h2>
      <PostContainer images={images} />
    </>
  );
}

InstagramGallery.propTypes = {
  objs: PropTypes.arrayOf(PropTypes.any)
}

export default InstagramGallery;
