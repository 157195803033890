import React, { Component } from 'react';
import styled from 'styled-components';
import { environment } from '../../utils';

const BackgroundGraphics = styled.div`
  background: url(${environment.REACT_APP_STATIC_URL}/img/2024/tausta.svg);
  background-size: cover;
  background-position: center bottom;
  position: fixed;
  z-index: -1;
  height: 120vh;
  width: 120vw;
  bottom: 3vh;
  right: 0;
  opacity: 0.2;
`;

class Background extends Component {
  render() {
    return <BackgroundGraphics />;
  }
}

export default Background;
