import React from 'react';
import styled from 'styled-components';
//import placeholderImg from '../../images/placeholder_dark.svg'
import dateFormat from 'dateformat';
import { environment } from '../../utils';

const ProgramBlock = styled.div`
  font-size: 24px;

  padding: 1.5rem;
  min-height: ${p => p.maintainance ? 'none' : '150px'};

  &::after {
    content: "";
    clear: both;
    display: table;
  }

  background-color: ${p => p.theme.color.containerBg};
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Column = styled.div`
  flex: ${p => p.flex};
  /* display: flex; */
  flex-direction: column;
`;

const Image = styled.img`
  height: 220px;
  width: 220px;
  background-size: cover;
  margin-right: 1.5rem;
`;

const Time = styled.span`
  font-size: 1em;
`;

const Title = styled.h4`
  margin: 0.5em 0;
  font-size: 1.3em;
  border-bottom: 1px solid ${p => p.theme.color.redDark};
  word-wrap: break-word;
`;

const Genre = styled.span`
  margin-top: -4px;
  color: ${p => p.theme.color.redDark};
  letter-spacing: 0.04em;
  font-size: 0.9em;
`;

const Author = styled.span`
  display: inline-block;
  margin: 0.5rem 0;
  font-weight: 500;
  font-size: 0.8em;
`;

const Paragraph = styled.p`
  font-size: 1em;
  line-height: 1.4;
  margin: 0.5em 0;
`;

const PlaceholderImage = ({ alt }) => (
  <Image
    src={`${environment.REACT_APP_STATIC_URL}/img/2024/ohjelmat/placeholder.png`}
    alt={alt}
  />
);

const ProgramImage = ({ src, alt }) => {
  // const originalHref = src.replace('/ohjelmat', '/ohjelmat/original');

  return (
    // <a href={originalHref}>
    <Image
      src={src}
      alt={alt}
    />
    // </a>
  );
}

const InfoScreenProgram = ({ p, oneDayPreview }) => {
  const maintainance = p.title === 'HUOLTOTAUKO';
  const img = p.image && p.image.trim()
    ? <ProgramImage src={p.image} alt={p.title} />
    : <PlaceholderImage alt={p.title} />;

  const startDate = dateFormat(p.start, 'HH:MM');
  const endDate = dateFormat(p.end, 'HH:MM');

  return (
    <ProgramBlock oneDayPreview={oneDayPreview} maintainance={maintainance}>
      <Column>
        {img}
      </Column>
      <Column flex={1}>
        <Time>{`${startDate} - ${endDate}`}</Time>
        <Title>{p.title} - <Author>{p.team}</Author></Title>
        {p.description && <Paragraph>{p.description}</Paragraph>}
        <Genre>{p.genre}</Genre>
      </Column>
    </ProgramBlock>
  );
}

export default InfoScreenProgram
