import { createGlobalStyle } from 'styled-components';

export const theme = {
  color: {
    bgBlue: '#1A1830',
    bgDark: 'rgba(33, 33, 33, 1)',
    grey100: 'rgba(30, 29, 50, 1)',
    white100: 'rgba(255, 255, 255, 1.0)',
    white70: 'rgba(255, 255, 255, 0.7)',
    white30: 'rgba(255, 255, 255, 0.3)',
    white10: 'rgba(255, 255, 255, 0.10)',
    
    // 2024 colors
    bgLight: '#FFDCD5',
    containerBg: 'rgba(255, 255, 255, 0.3)',
    blueDark: '#204ECF',
    blueLight: '#83A5F2',
    redDark: '#FF4B32', // #F26749
    redMedium: '#FF9C94', // #F9A197
    bodyText: '#AB3521', // #F26749
    firstBoxBg: 'rgba(131, 165, 242, .31)',
    firstBoxText: '#204ECF',
    countdownBg: 'rgba(255, 156, 148, .5)',
    linkColor: '#FF4B32', // #F26749
    // linkColorHover: '#FF9C94',
    headingColor: '#AB3521', // #F26749
    headerLinkColor: '#FF4B32', // #F26749
    englishLinkColor: '#204ECF',
    galleryHoverBg: '#204ECF',
    galleryHoverText: 'white',

    // make sure we can see unused colors
    orange100: 'lime',
    orangedark100: 'lime',
    turquoise100: 'lime',
    pink100: 'lime',
    darkpink100: 'lime',
    bgGreen: 'lime',
    lightOrange: 'lime',
    realOrange: 'lime',
    darkOrange: 'lime',
    white2: 'lime',

  },
  font: {
    heading: 'Montserrat, sans-serif', // Similar to Avenir, serif headings.
    body: 'Montserrat, sans-serif', // Similar to Avenir, body text.
  }
};

export const GlobalStyle = createGlobalStyle`
    body {
      font-family: ${theme.font.body};
      font-weight: 500;
      letter-spacing: 0.5px;
      max-width: 1000px;
      margin: 0 auto 0;
      padding: 1rem;
      line-height: 1.5rem;
      overflow-x: hidden;
      position: relative;
    }

    html {
      box-sizing: border-box;
      background-color: ${theme.color.bgLight};
      color: ${theme.color.bodyText};
    }

    *,
    *::before,
    *::after {
      box-sizing: inherit;
    }

    h1,
    h2 {
      line-height: 2rem;
      font-size: 1.5rem;
      font-family: ${theme.font.heading};
      font-weight: bolder;
      color: ${theme.color.headingColor};
      display: flex;
      margin: 0;
      padding: 1.5rem 0 0;
    }

    h3 {
      font-size: 1.5rem;
      color: ${theme.color.headingColor};
    }

    a {
      color: ${theme.color.linkColor};
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    strong {
      font-weight: 700;
    }
`;

export default GlobalStyle;
