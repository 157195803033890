import React from 'react';
import styled from 'styled-components';
import { environment } from '../../../utils';
import { useTranslation } from 'react-i18next';

const Main = styled.main`
  background-color: ${p => p.theme.color.containerBg};
  padding: 1rem;
`;

const Paragraph = styled.p`
  text-align: left;
  max-width: 70ch;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  justify-content: space-between;
`;

const Column = styled.span`
  margin-bottom: 1rem;
`;

const Title = styled.h2`
  text-align: left;
`;

const AudioPlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -0.5rem;
`;

const AudioPlayer = styled.span`
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem 1rem;

  @supports (-moz-appearance: none) {
    width: 30%; /* fixes firefox width bug */
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const AudioPlayerLabel = styled.label`
  margin-bottom: 0.5rem;

  @media screen and (max-width: 700px) {
    margin-bottom: 0;
    align-self: center;
  }

  @media screen and (max-width: 500px) {
    margin-bottom: 0.5rem;
  }
`;

const Companies = () => {
  const { t } = useTranslation();
  return (
    <Main>
      <Paragraph>
        {t('companies.contact')}:{' '}
        <a href="mailto:yrityssuhteet@radiodiodi.fi">
          yrityssuhteet@radiodiodi.fi
        </a>
      </Paragraph>

      <Title>{t('companies.title')}</Title>
      <Paragraph>{t('companies.paragraph1')}</Paragraph>
      <Paragraph>{t('companies.paragraph2')}</Paragraph>
      <Paragraph>{t('companies.paragraph3')}</Paragraph>

      <ColumnContainer>
        <Column>
          <h4>{t('companies.ads.title')}</h4>
          <ul>
            <li>{t('companies.ads.1')}</li>
            <li>{t('companies.ads.2')}</li>
            <li>{t('companies.ads.3')}</li>
          </ul>
        </Column>
        <Column>
          <h4>{t('companies.visibility.title')}</h4>
          <ul>
            <li>{t('companies.visibility.1')}</li>
            <li>{t('companies.visibility.2')}</li>
            <li>{t('companies.visibility.3')}</li>
          </ul>
        </Column>
      </ColumnContainer>

      <Paragraph>{t('companies.interested')}</Paragraph>
      <Paragraph>
        <a href="mailto:yrityssuhteet@radiodiodi.fi">
          yrityssuhteet@radiodiodi.fi
        </a>
      </Paragraph>
      <Title>{t('companies.previousAds')}</Title>

      <AudioPlayerContainer>
        <AudioPlayer>
          <AudioPlayerLabel>
            Vincit &ndash; ATK-ohjelmoitsija
          </AudioPlayerLabel>
          <audio controls>
            <source
              src={`${environment.REACT_APP_STATIC_URL}/audio/vincit1.wav`}
              type="audio/wav"
            />
          </audio>
        </AudioPlayer>

        <AudioPlayer>
          <AudioPlayerLabel>SRV &ndash; Diego &amp; Alberto</AudioPlayerLabel>
          <audio controls>
            <source
              src={`${environment.REACT_APP_STATIC_URL}/audio/srv1.wav`}
              type="audio/wav"
            />
          </audio>
        </AudioPlayer>

        <AudioPlayer>
          <AudioPlayerLabel>Futurice &ndash; Chilicorn.org</AudioPlayerLabel>
          <audio controls>
            <source
              src={`${environment.REACT_APP_STATIC_URL}/audio/futurice-spiceprogram-eng.wav`}
              type="audio/wav"
            />
          </audio>
        </AudioPlayer>
      </AudioPlayerContainer>
    </Main>
  );
}

export default Companies;
