import React from 'react';
import styled from 'styled-components';

import Person from './Person';
import { environment } from '../../../utils';

const Gallery = styled.section`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

const IMG_URL = `${environment.REACT_APP_STATIC_URL}/img/2024/toimitus/`;

const people = [
  {
    name: 'Alexander',
    role: 'frontpage.editorialTeam.editor',
    img: `${IMG_URL}allu.jpg`,
    mail: 'paatoimittaja@radiodiodi.fi'
  },
  {
   name: 'Veera',
   role: 'frontpage.editorialTeam.promoRep',
   img: `${IMG_URL}veera.jpg`,
  mail: '@radiodiodi.fi'
  },
  {
    name: 'Leo',
    role: 'frontpage.editorialTeam.oltermanni',
    img: `${IMG_URL}leo.jpg`,
    mail: 'leo@radiodiodi.fi'
  },
  {
    name: 'Ilkka',
    role: 'frontpage.editorialTeam.studio',
    img: `${IMG_URL}ilkka.jpg`,
    mail: 'studio@radiodiodi.fi'
  },
{
     name: 'Nelli',
     role: 'frontpage.editorialTeam.corporate',
     img: `${IMG_URL}nelli.jpg`,
     mail: 'yrityssuhteet@radiodiodi.fi'
   },
   {
    name: 'Anna',
    role: 'frontpage.editorialTeam.communications',
    img: `${IMG_URL}anna.jpg`,
    mail: 'viestinta@radiodiodi.fi'
   },
  {
    name: 'Anni',
    role: 'frontpage.editorialTeam.graphics',
    img: `${IMG_URL}anni.jpg`,
    mail: 'anni@radiodiodi.fi'
  },
  {
    name: 'Aaron',
    role: 'frontpage.editorialTeam.construction',
    img: `${IMG_URL}aaron.jpg`,
    mail: 'vili@radiodiodi.fi'
  },
  {
    name: 'Petrus',
    role: 'frontpage.editorialTeam.tech',
    img: `${IMG_URL}petrus.jpg`,
    mail: 'petrus@radiodiodi.fi'
  },
  {
    name: 'Samuli',
    role: 'frontpage.editorialTeam.tech',
    img: `${IMG_URL}samuli.jpg`,
    mail: 'samuli@radiodiodi.fi'
  }
];

const ImageGallery = () => {
  return (
    <Gallery>
        {people.map((person, index) => (
          <Person key={index} {...person} />
        ))}
    </Gallery>
  );
}

export default ImageGallery;
