import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useParams } from 'react-router-dom';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Background from '../common/Background';
import Frontpage from '../pages/frontpage/Frontpage';
import Companies from '../pages/sponsors/Companies';
import Registration from '../pages/registration/Registration';
import Library from '../pages/library/Library';
// import Login from '../pages/admin/Login';
import Guide from '../pages/guide/Guide';
// import English from '../pages/english/English';
import CalendarCallback from '../pages/calendar-callback/CalendarCallback';
import RegistrationSuccess from '../pages/registration/Success';
import RegistrationError from '../pages/registration/Error';
// import AdminRegistrationsPage from '../pages/admin/AdminRegistrationsPage';
// import AdminShoutboxPage from '../pages/admin/AdminShoutboxPage';
// import AdminBansPage from '../pages/admin/AdminBansPage';
// import AdminReservedPage from '../pages/admin/AdminReservedPage';
// import AdminRecruitmentPage from '../pages/admin/AdminRecruitmentPage';
// import Player from '../common/Player'
// import Shoutbox from '../common/Shoutbox'
import ChatProvider from '../common/ChatProvider';
import InfoScreen from '../pages/infoscreen/InfoScreen';
import Recruitment from '../pages/recruitment/Recruitment';
import RecruitmentSuccess from '../pages/recruitment/Success';
import RecruitmentError from '../pages/recruitment/Error';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const defaultLanguage = 'fi';

  useEffect(() => {
    if (lang === undefined || !['fi', 'en'].includes(lang)) {
      i18n.changeLanguage(defaultLanguage);
    } else {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n, defaultLanguage]);

  return null;
};

export default () => {
  return (
    <Router>
      <ChatProvider>
      <Switch>
          {/* <Route exact path="/huutis" component={Shoutbox} /> */}
          <Route exact path="/infoscreen" component={InfoScreen} />
          <Route path="/:lang?" >
            <LanguageSwitcher />
            <Background />
            <Header />
            {/* <Player /> */}
            <Switch>
              <Route exact path="/" component={Frontpage} />
              <Route exact path="/fi" component={Frontpage} />
              <Route exact path="/en" component={Frontpage} />
              <Route path="/:lang?/ilmo/error" component={RegistrationError} />
              <Route path="/:lang?/ilmo/success" component={RegistrationSuccess} />
              <Route path="/:lang?/ilmo" component={Registration} />
              <Route path="/:lang?/for-companies" component={Companies} />
              <Route path="/:lang?/guide" component={Guide} />
              <Route path="/:lang?/library" component={Library} />
              {/* <Route path="/admin/bans" component={AdminBansPage} />
              <Route path="/admin/registrations" component={AdminRegistrationsPage} />
              <Route path="/admin/reserved_users" component={AdminReservedPage} />
              <Route path="/admin/shoutbox" component={AdminShoutboxPage} />
              <Route path="/admin/recruitment" component={AdminRecruitmentPage} />
              <Route path="/login" component={Login} /> */}
              <Redirect from="/admin" to="/admin/shoutbox" />
              <Route path="/:lang?/rekry/success" component={RecruitmentSuccess} />
              <Route path="/:lang?/rekry/error" component={RecruitmentError} />
              <Route path="/:lang?/rekry" component={Recruitment} />
              {/* <Route path="/english" component={English} /> */}
              <Route path="/callback" component={CalendarCallback} />
              {/* <Route path="/shoutbox" component={Shoutbox} /> */}
              <Route component={() => <h1>404</h1>} />
            </Switch>
            <Footer />
          </Route>
      </Switch>
      </ChatProvider>
    </Router>
  );
}
