import React, { Component } from 'react';
import styled from 'styled-components';
import i18next from '../../../i18n';
import { Trans, withTranslation } from 'react-i18next';

const Container = styled.div`
  margin-bottom: 2rem;
`;

const Paragraph = styled.div`
  max-width: 700px;
  flex-grow: 1;

  hr {
    width: 50%;
    text-align: left;
  }
`;

const Title = styled.h3`
  margin: 0;
  height: 4rem;
`;

class RecruitmentError extends Component {
  render() {
    const t = i18next.t;
    return (
      <Container>
        <Paragraph>
          <Title>{t('rekry.error.title')}</Title>
          <Paragraph>
            <Trans 
              i18nKey="rekry.error.description" 
              components={[<a href="mailto:rekry@radiodiodi.fi"> </a>]} />
          </Paragraph>
        </Paragraph>
      </Container>
    );
  }
}

export default withTranslation()(RecruitmentError);
