import React, { useState, useEffect } from 'react';
import axios from 'axios';
import InstagramGallery from './InstagramGallery';
import { environment } from '../../utils';

const Instagram = () => {
  const [posts, setPosts] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    (async() => {
      const count = environment.REACT_APP_INSTAGRAM_IMAGE_COUNT;
      const url = `${environment.REACT_APP_BACKEND_HTTP_URL}/api/instagram_feed?count=${count}`;

      try {
        const resp = await axios.get(url);
        const data = resp.data
        const postlist = data.data;

        if ( postlist && postlist !== undefined ) {
          setPosts(postlist)
        } else {
          setError(true)
        }
      } catch ( err ) {
        setError(true)
        console.log(err);
      }
    })();
  }, []);

  if (!posts || error) {
    console.error("Failed to load Instagram posts")
    return null;
  }

  return <InstagramGallery objs={ posts } />
}

export default Instagram;
