import React from 'react';
import styled from 'styled-components';
import Link from '../../common/Link';

import RegistrationForm from './RegistrationForm';
import { linkToTelegramChat } from '../../../utils';
import { useTranslation, Trans } from 'react-i18next';

const Main = styled.main`
  background-color: ${p => p.theme.color.containerBg};
  padding: 1rem;

  p {
    max-width: 800px;
  }
`;

export const signupOpens = new Date('2024-02-13T22:00:00Z'); // initial signup
export const signupCloses = new Date('2024-03-17T22:00:00Z'); 
export const postSignupOpens = new Date('2024-03-26T22:00:00Z'); // signup for remaining spots
export const postSignupCloses = new Date('2024-04-26T09:00:00Z');

const Registration = () => {
  const { t } = useTranslation();

  if (new Date() >= postSignupCloses) {
    return (
      <Main>
        <h2>{t('signup.closed.title')}</h2>
        <p>
          <Trans
            i18nKey="signup.closed.remaining"
            components={[<a href="mailto:toimitus@radiodiodi.fi"> </a>]}
          />
        </p>
      </Main>
    );
  }

  return (
    <Main>
      {new Date() >= postSignupOpens
        ? <h2>{t('signup.postregistration.open')}</h2>
        : new Date() >= signupCloses
        ? <h2>{t('signup.postregistration.soon')}</h2>
        : new Date() >= signupOpens
        ? <h2>{t('signup.signup.title')}</h2>
        : <h2>{t('signup.preRegistration.pageTitle')}</h2>}

      {new Date() >= postSignupOpens && <h4><i>{t('signup.notice')}</i></h4>}

      <p>{t('signup.paragraph1')}</p>
      <p>
        <Trans 
          i18nKey="signup.paragraph2" 
          components={[
            <a title="Telegram-tiedotus" href={linkToTelegramChat('radiodiodi')} target="_blank" rel="noopener noreferrer"> </a>,
            <a title="Telegram-keskustelu" href={linkToTelegramChat('radiodiodichat')} target="_blank" rel="noopener noreferrer"> </a>
          ]} 
        />
      </p>
      <p>
        <Trans 
          i18nKey="signup.paragraph3" 
          components={[
            <Link to="/guide/checklist" />,
            <Link to="/guide/faq" />,
            <Link to="/library" />
          ]} 
        />
      </p>
      <p>
        {t('signup.paragraph4')} <a href="mailto:toimitus@radiodiodi.fi">toimitus@radiodiodi.fi</a>
      </p>

      <RegistrationForm
        signupOpens={new Date() < signupCloses ? signupOpens : postSignupOpens}
        signupCloses={new Date() < signupCloses ? signupCloses : postSignupCloses}
      />
    </Main>
  );
}

export default Registration;
