import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import ReactLink from './Link';
import Slogan from './Slogan';
import { linkToTelegramChat } from '../../utils';
import { environment } from '../../utils';
import { useTranslation } from 'react-i18next';

const logoURL = `${environment.REACT_APP_STATIC_URL}/img/2024/2024_header.svg`;

const HeaderContainer = styled.header`
  margin-bottom: 2rem;
`;

const HeaderNavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.3rem;
`;

const Logo = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: -5%;

  @media screen and (max-width: 499px) {
    margin-bottom: 20px;
  }
`;


const SocialMediaButton = styled.i`
  font-size: 2rem;
`;

const SocialMediaLink = styled.a`
  color: ${p => p.theme.color.headerLinkColor};
  transition: color 0.2s ease;

  &:hover, &:focus {
    color: ${p => p.theme.color.headerLinkColorHover};
  }

  margin: 0 0.5rem 0;
`;

const SocialMediaContainer = styled.span`
  display: flex;
  flex-flow: row nowrap;
  margin-right: auto;
  align-items: flex-end;  
`;

const Link = styled(ReactLink)`
  font-size: 1.1rem;
  color: ${p => p.color || (p.blue && p.theme.color.blueDark) || p.theme.color.headerLinkColor};
  margin-left: 0.8rem;
  transition: color 0.3s ease;
  font-weight: 500;

  &:hover {
    color: ${p => p.theme.color.blueDark};
    text-decoration: none;
  }
`;

const MobileLink = styled(Link)`
  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

const DesktopLink = styled(Link)`
  @media screen and (max-width: 999px) {
    display: none;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Menu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 1000px) {
    flex-direction: row;
  }
`;

const BurgerButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 1.8rem;
  padding: 0.5rem;
  margin-right: -0.5rem;
  margin-left: 1rem;
  color: ${p => p.active ? p.theme.color.redDark : p.theme.color.redDark};

  &:hover {
    color: ${p => p.theme.color.redMedium};
  }

  @media screen and (min-width: 1000px) {
    display: none;
  }
`;

const Freq = styled.div`
  min-height: 2.5em;
  margin: 0 0.3em 0
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 1em;
  color: ${p => p.theme.color.redDark};

  @media screen and (max-width: 499px) {
    font-size: 1.3rem;
  }
`;

const Social = () => {
  const { t } = useTranslation();
  return (
    <SocialMediaContainer>
      <SocialMediaLink target="_blank" rel="noopener noreferrer" title="Instagram" float href="https://instagram.com/radiodiodi">
        <SocialMediaButton className="fab fa-instagram" />
      </SocialMediaLink>
      <SocialMediaLink target="_blank" rel="noopener noreferrer" title="Facebook" href="https://www.facebook.com/radiodiodi">
        <SocialMediaButton className="fab fa-facebook" />
      </SocialMediaLink>
      <SocialMediaLink title={t('navbar.tgDiscussion')} href={linkToTelegramChat('radiodiodichat')} target="_blank" rel="noopener noreferrer">
        <SocialMediaButton className="fab fa-telegram" />
      </SocialMediaLink>
      <SocialMediaLink title={t('navbar.tgInfo')} href={linkToTelegramChat('radiodiodi')} target="_blank" rel="noopener noreferrer">
        <SocialMediaButton className="fab fa-telegram" />
      </SocialMediaLink>
    </SocialMediaContainer>
  );
}

const Nav = styled.nav`
  display: ${p => p.expand ? "flex" : "none"};
  flex-direction: column;
  align-items: flex-end;
  line-height: 1.8;

  @media screen and (min-width: 1000px) {
    display: flex;
    flex-direction: row;
  }
`;

export const HeaderLogo = () => <Logo src={logoURL} width="3820" height="1200" />;

const Header = () => {
  const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleMobileMenuExpanded = () => {
    setMobileMenuExpanded(!mobileMenuExpanded);
  }
  
  const location = useLocation();

  const switchRouteLanguage = () => {
    if (i18n.language === 'en') {
      return location.pathname.replace('/en', '');
    } else {
      return `/en${location.pathname}`;
    }
  }

  const renderNavigation = (expand) => {
    return (
      <Nav expand={expand}>
        <Link to="/">{t('navbar.frontpage')}</Link>
        {/* <Link to="/ilmo">{t('navbar.signup')}</Link> */}
        {/* <Link to="/rekry">{t('navbar.recruitment')}</Link> */}
        <Link to="/library">{t('navbar.library')}</Link>
        <Link to="/for-companies">{t('navbar.forCompanies')}</Link>
        {/* <Link to="/for-companies">Yhteistyökumppaniksi?</Link> */}
        <Link to="/guide">{t('navbar.forProgrammeMakers')}</Link>
        <DesktopLink blue={1} localize={false} to={switchRouteLanguage()}>{i18n.language === 'en' ? 'Suomeksi' : 'In English'}</DesktopLink>
      </Nav>
    );
  };

  return (
    <HeaderContainer>
      <ReactLink to="/">
        <HeaderLogo />
      </ReactLink>
      <Row>
        <Slogan />
        <Freq>105,8 MHz</Freq>
      </Row>
      <HeaderNavContainer>
        <Menu>
          <Row>
            <Social />
            <MobileLink blue={1} localize={false} to={switchRouteLanguage()}>{i18n.language === 'en' ? 'Suomeksi' : 'In English'}</MobileLink>
            <BurgerButton active={mobileMenuExpanded} onClick={toggleMobileMenuExpanded}>
              <i className="fa fa-bars" />
            </BurgerButton>
          </Row>
          {renderNavigation(mobileMenuExpanded)}
        </Menu>
      </HeaderNavContainer>
    </HeaderContainer>
  );
}

export default Header;
