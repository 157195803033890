import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import InfoScreenCalendar from '../../calendar/InfoScreenCalendar';
import { HeaderLogo } from '../../common/Header';
import Countdown from '../../common/Countdown';

const CountdownContainer = styled.div`
  margin-bottom: 4rem;
`;

const CountdownTitle = styled.h1`
  display: flex;
  justify-content: space-between;
  font-size: 2rem;
  margin: 0 0 2rem;
`;

const countdownTo = new Date('2024-04-15T12:00:00+03:00');

const InfoScreen = () => {
  const [showCountdown, setShowCountdown] = useState(Date.now() < countdownTo);

  useEffect(() => {
    const timer = setInterval(() => setShowCountdown(Date.now() < countdownTo), 5000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => window.location.reload(), 300e3);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <HeaderLogo />
      {showCountdown && (
        <CountdownContainer>
          <CountdownTitle>
            <div>Lähetyskauden alkuun:</div>
            <div>Broadcast will start in:</div>
          </CountdownTitle>
          <Countdown to={countdownTo} infoScreen />
        </CountdownContainer>
      )}
      <InfoScreenCalendar count={showCountdown ? 4 : 5} />
    </>
  );
}

export default InfoScreen;
