import React, { Component } from 'react';
import { withRouter } from "react-router";
import axios from 'axios';
import {
  Form as InformedForm,
  Text as InformedText,
  TextArea as InformedTextArea,
  RadioGroup as InformedRadioGroup,
  Radio,
  asField,
} from 'informed';
import Link from '../../common/Link';
import styled from 'styled-components';
import PropositionPicker from '../../common/PropositionPicker';
import { environment } from '../../../utils';
import i18next from '../../../i18n'
import { Trans } from 'react-i18next';

const BACKEND_URL = environment.REACT_APP_BACKEND_HTTP_URL;

const Form = styled(InformedForm)`
  margin: 3rem 0;

  input,
  text,
  textarea {
    font-family: inherit;
    font-size: 1rem;
    border: 2px solid ${p => p.theme.color.redDark};
  }

  p {
    max-width: 70ch;
  }

  fieldset {
    border: none;
    border-top: 0.2rem solid ${p => p.theme.color.redDark};
    margin-top: 2rem;
  }

  legend {
    font-size: 1.2rem;
    padding: 0 0.5em;
  }

  strong {
    color: ${p => p.theme.color.redDark};
  }

`;

const Fieldset = styled.fieldset`
  @media screen and (min-width: 800px) {
    label {
      display: flex;
      justify-content: space-between;
    }
  }
`

const CustomNumberField = asField(({ fieldState, fieldApi, ...props }) => {
  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, initialValue, forwardedRef, ...rest } = props;
  return (
    <input
      {...rest}
      type="number"
      ref={forwardedRef}
      step={0.5}
      min={1}
      max={10}
      value={value || initialValue}
      onChange={e => {
        setValue(e.target.value);
        if (onChange) {
          onChange(e);
        }
      }}
      onBlur={e => {
        setTouched(true);
        if (onBlur) {
          onBlur(e);
        }
      }}
      />
  );
});

const NumberField = styled(CustomNumberField)`
  padding: 0.5rem;
  max-width: 500px;
  width: 100%;

`;

const StyledText = styled(InformedText)`
  padding: 0.5rem;
  max-width: 500px;
  width: 100%;
`;

const StyledTextArea = styled(InformedTextArea)`
  padding: 0.5rem;
  max-width: 500px;
  width: 100%;
`;

const Text = (props) => (
  <StyledText validate={notEmptyString} {...props} />
);

const formatRegCode = (code) => {
  return code ? code.toUpperCase().replace(/[^A-Z0-9-]/g, '') : '';
};

const RegCode = (props) => (
  <Text {...props} parse={formatRegCode} format={formatRegCode} />
);

const TextArea = (props) => (
  <StyledTextArea validate={!props.optional && notEmptyString} {...props} />
);

const RadioGroup = (props) => (
  <InformedRadioGroup validate={notEmptyString} {...props} />
);

const Label = styled.label`
  display: block;
  margin: 1em 0;
  font-weight: 600;

  input:not([type="checkbox"]):not([type="radio"]),
  textarea {
    display: block;
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin: 0 1rem;
  }
`;

const Submit = styled.button`
  display: block;
  width: 7em;
  padding: 1em 1.5em;
  margin: 2em auto;
  background-color: ${p => p.theme.color.redDark};
  font-size: 1.1rem;
  color: ${p => p.theme.color.white100};

  border: none;
  outline: none;
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: ${p => p.theme.color.redMedium};
  }
`;

const Info = styled.p`
  margin-left: 2em;
  color: ${p => p.theme.color.redDark};

  a {
    color: ${p => p.theme.color.bodyText};
  }
`

const Error = styled.div`
  color: ${p => p.theme.color.redDark};
`;

const Tag = styled.span`
  color: ${p => p.theme.color.blueDark};
  font-weight: 600;
`;

const notEmptyString = value => !value ? i18next.t('signup.empty') : undefined;
const validatePropositions = propositions => {
  if (!propositions || propositions.length === 0) {
    return 'Ehdota vähintään yhtä aikaväliä';
  }

  for (const p of propositions) {
    if (!p.date) {
      return i18next.t('signup.propositions.dateMissing');
    }
    if (!p.startTime) {
      return i18next.t('signup.propositions.startMissing');
    }
    if (!p.endTime) {
      return i18next.t('signup.propositions.endMissing');
    }
  };

  return undefined;
}

class RegistrationForm extends Component {
  state = { preRegCodeOk: false };

  setFormApi = (formApi) => {
    this.formApi = formApi;
  }

  handlePreRegistration = async () => {
    const state = this.formApi.getState();
    try {
      const resp = await axios.post(`${BACKEND_URL}/api/register_code`, state.values);
      console.log(resp);
      if (resp.data.ok) {
        this.setState({ preRegCode: state.values.preRegCode, preRegCodeOk: true });
      } else {
        this.formApi.setError('preRegCode', resp.data.error);
      }
    } catch (err) {
      console.error(err);
      this.formApi.setError('preRegCode', 'Koodin tarkistus epäonnistui');
    }
    this.rerender();
  }

  handleSubmit = async () => {
    const { history } = this.props;
    const state = this.formApi.getState();
    console.log(state);

    try {
      if (state.values.prerecord === 'live') {
        // remove null propositions
        state.values.propositions = state.values.propositions.filter(p => p.date);
      }
      if (this.state.preRegCode) state.values.preRegCode = this.state.preRegCode;
      const resp = await axios.post(`${BACKEND_URL}/api/register`, state.values, {
        headers: {
          'Accept-Language': i18next.language
        }
      });
      console.log(resp);
      const email = state.values.email;
      history.push(`/ilmo/success?email=${email}`);
    } catch (err) {
      console.error(err);
      history.push('/ilmo/error');
    }
  }

  getErrors = () => this.formApi
    ? this.formApi.getState().errors || {}
    : {};

  getValues = () => this.formApi
    ? this.formApi.getState().values || {}
    : {};

  renderErrors = () => {
    const errors = this.getErrors();
    const hasErrors = !!Object.keys(errors).length;
    if (!hasErrors) return null;

    return (
      <Error>{i18next.t('signup.errors')}</Error>
    )
  }

  rerender = () => {
    // force rerender
    this.setState({});
  }

  handleSubmitFailure = (data) => {
    this.rerender();
    return true;
  }

  setPropositionValues = (value) => {
    const formApi = this.formApi;
    if (formApi) formApi.setValue('propositions', value);
  }

  getPropositionValues = () => {
    const formApi = this.formApi;
    const val = formApi ? formApi.getValue('propositions') : [];
    return val || [];
  }

  render() {
    const errors = this.getErrors();
    const values = this.getValues();
    const t = i18next.t;

    const isClosed = new Date() > this.props.signupCloses;
    const isOpen = new Date() > this.props.signupOpens && !isClosed;

    if (!this.state.preRegCodeOk && !isOpen) {
      return (
        <Form getApi={this.setFormApi}
          onSubmit={this.handlePreRegistration}
          onSubmitFailure={this.handleSubmitFailure}
          initialValues={{
            propositions: [],
          }}>
          <Fieldset>
            <legend>{t(isClosed ? 'signup.signup' : 'signup.preregistration.title')}</legend>
            <Info>{t(isClosed ? 'signup.postregistration.info' : 'signup.preregistration.info')}</Info>
            <Label>
              {t('signup.preregistration.code')}
              {errors.preRegCode && <Error>{errors.preRegCode}</Error>}
              <RegCode
                field="preRegCode"
                placeholder="ESIMERKKI-X123" />
            </Label>
            <Submit type="submit">{t('signup.preregistration.check')}</Submit>
          </Fieldset>
        </Form>
      );
    }

    return (
      <Form getApi={this.setFormApi}
        onSubmit={this.handleSubmit}
        onSubmitFailure={this.handleSubmitFailure}
        initialValues={{
          propositions: [],
        }}>
        <Fieldset>
          <legend>{t('signup.programmeInfo.title')}</legend>
          <Label>
            {t('signup.programmeInfo.name')}
            {errors.name && <Error>{errors.name}</Error>}
            <Text
              field="name"
              placeholder={t('signup.programmeInfo.namePlaceholder')} />
          </Label>
          <Label>
            {t('signup.programmeInfo.description')}
            {errors.description && <Error>{errors.description}</Error>}
            <TextArea
              field="description"
              placeholder={t('signup.programmeInfo.descriptionPlaceholder')}
              rows="5"
              maxLength="400"
            />
          </Label>
          <Label>
            {t('signup.programmeInfo.team')}
            {errors.team && <Error>{errors.team}</Error>}
            <Text
              field="team"
              placeholder={t('signup.programmeInfo.teamPlaceholder')} />
          </Label>
          <Label>
            {t('signup.programmeInfo.responsible')}
            {errors.responsible && <Error>{errors.responsible}</Error>}
            <Text
              field="responsible"
              placeholder={t('signup.programmeInfo.responsiblePlaceholder')}
            />
          </Label>
          <Label>
            {t('signup.programmeInfo.email')}
            {errors.email && <Error>{errors.email}</Error>}
            <Text
              field="email"
              type="email"
              placeholder={t('signup.programmeInfo.emailPlaceholder')}
            />
          </Label>
          <Label>
            {t('signup.programmeInfo.genre')}
            {errors.genre && <Error>{errors.genre}</Error>}
            <Text
              field="genre"
              placeholder={t('signup.programmeInfo.genrePlaceholder')} />
          </Label>
          <Label>
            {t('signup.programmeInfo.participants')}
            {errors.participants && <Error>{errors.participants}</Error>}
            <Text
              field="participants"
              type="number"
              min={1}
              max={5}
              placeholder={t('signup.programmeInfo.participantsPlaceholder')} />
            {/* <p>Jos ohjelmaasi on aikeissa tulla suuri joukko ihmisiä, keskustele ensin Radiodiodin toimituksen kanssa.</p> */}
          </Label>
          <Label>
            {t('signup.programmeInfo.duration')}
            {errors.duration && <Error>{errors.duration}</Error>}
            <NumberField field="duration" placeholder={t('signup.programmeInfo.durationPlaceholder')} />
          </Label>
          <Info>
            {t('signup.programmeInfo.durationInfo')}
            <br />
            <Trans 
              i18nKey="signup.programmeInfo.durationCalculator" 
              components={[<Link to="/guide/checklist#calculator" newTab={true} />]}
            />
          </Info>
          <Label>
            {t('signup.programmeInfo.additionalInfo')}
            {errors.info && <Error>{errors.info}</Error>}
            <TextArea
              field="info"
              placeholder={t('signup.programmeInfo.additionalInfoPlaceholder')}
              optional="true"
              rows="4"
            />
          </Label>
        </Fieldset>
        <fieldset>
          <legend>{t('signup.programmeInfo.producer')}</legend>
          <Label>
            <p>{t('signup.programmeInfo.producerInfo')}</p>
            {errors.producer && <Error>{errors.producer}</Error>}
            <RadioGroup
              field="producer"
              onValueChange={this.rerender}>
              <Label>
                <Radio value="ei omaa tuottajaa" />
                {t('signup.programmeInfo.producerNeeded')}
              </Label>
              <Label>
                <Radio value="oma tuottaja" />
                {t('signup.programmeInfo.ownProducer')}
              </Label>
              {values.producer === 'oma tuottaja' && (
                <Info><Trans 
                  i18nKey="signup.programmeInfo.producerTraining" 
                  components={[<a href="mailto:studio@radiodiodi.fi"> </a>]} 
                /></Info>
              )}
            </RadioGroup>
          </Label>
        </fieldset>
        <fieldset>
          <legend>{t('signup.programmeInfo.prerecorded')}</legend>
          <Label>
            <p>{t('signup.programmeInfo.prerecordedInfo')}</p>
            {errors.prerecord && <Error>{errors.prerecord}</Error>}
            <RadioGroup
              field="prerecord"
              onValueChange={this.rerender}>
              <Label>
                <Radio value="live" />
                {t('signup.programmeInfo.liveProgramme')}
              </Label>
              <Label>
                <Radio value="oma" />
                {t('signup.programmeInfo.prerecordedProgramme')}
              </Label>
            </RadioGroup>
          </Label>
        </fieldset>
        {values.prerecord === 'live' &&
        <fieldset>
          <legend>{t('signup.programmeInfo.schedule')}</legend>
          <p>{t('signup.programmeInfo.scheduleInfo')}</p>
          {/* <strong>Varmista, että valitsemasi aikaväli on vapaana ohjelmakalenterissa!</strong> */}
          <p>
            <Trans 
              i18nKey="signup.programmeInfo.scheduleNight" 
              components={[<a href="mailto:toimitus@radiodiodi.fi"> </a>]} 
            />
          </p>
          <p><strong>{t('signup.programmeInfo.48hours')}</strong></p>
          {/* <p>Jälki-ilmoittautumisen yhteydessä emme pysty takaamaan sinulle parhaiten sopivaa aikaa. Kannattaa tarkistaa vapaat ajat ohjelmakalenterista, jota päivitetään alkuviikosta.</p> */}
          {errors.propositions && <Error>{errors.propositions}</Error>}
          <PropositionPicker
            field="propositions"
            validate={validatePropositions}
            getValues={this.getPropositionValues}
            setValues={this.setPropositionValues}
          />
        </fieldset>
        }
        <fieldset>
          <legend>{t('signup.programmeInfo.photo')}</legend>
          <p>
            <Trans 
              i18nKey="signup.programmeInfo.photoInfo" 
              components={[<a href="mailto:kuvat@radiodiodi.fi"> </a>]} 
            />
          </p>
          {/* <Label>
            Valokuvausaika
            {errors.photoshoot && <Error>{errors.photoshoot}</Error>}
            <RadioGroup
              field="photoshoot"
              onValueChange={this.rerender}>
              <Label>
                <Radio value="3.4." />
                3.4. klo 18
              </Label>
              <Label>
                <Radio value="4.4." />
                4.4. klo 18
              </Label>
              <Label>
                <Radio value="oma kuva" />
                Kuvaan julkaisukelpoisen kuvan itse
              </Label>
              {values.photoshoot === 'oma kuva' && (
                <Info>Jos haluat kuvata oman kuvasi, varmista, että se toimii myös 1:1-kuvasuhteessa (neliö) ja että se on korkealaatuinen valotuksen sekä kuvanlaadun osalta.</Info>
              )}
              <Label>
                <Radio value="mikään ei sovi" />
                Valitettavasti mikään vaihtoehto ei sovi
              </Label>
              {values.photoshoot === 'mikään ei sovi' && (
                <Info>Harmin paikka! Käytämme ohjelmasi kuvana oletusgrafiikkaa.</Info>
              )}
            </RadioGroup>
          </Label> */}
        </fieldset>
        <fieldset>
          <p>{t('signup.programmeInfo.questions')} <a href="mailto:toimitus@radiodiodi.fi">toimitus@radiodiodi.fi.</a></p>
          <p>
            <Trans 
              i18nKey="signup.programmeInfo.socialMedia" 
              components={[
                <Tag>#radiodiodi</Tag>,
                <Tag>@radiodiodi</Tag>,
                <Link to="/" />
              ]} 
            />
          </p>
          {this.renderErrors()}
          <br />
          <Info style={{textAlign: "center", margin: "auto"}}>{t('signup.programmeInfo.formConcent')}
          </Info>
          <Submit type="submit">{t('signup.programmeInfo.send')}</Submit>
        </fieldset>
      </Form>
    );
  }
}

export default withRouter(RegistrationForm);
