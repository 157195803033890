import React from 'react';
import { parseSearch } from '../../../utils';

const CalendarCallback = ({ location }) => {
  const { search } = location;
  const queryParams = parseSearch(search);
  return (
    <div>
      <h1>Google calendar authorization</h1>
      <p>Copy and paste this to your terminal:</p>
      <pre>
        { queryParams.code || 'No code in query params. Something went wrong!' }
      </pre>
      <p>There might be no prompt for the code but paste it anyway. Restart your backend afterwards!</p>
    </div>
  )
};

export default CalendarCallback;
