import React from 'react';
import styled from 'styled-components';

// import RecruitmentForm from './RecruitmentForm';
// import { useTranslation, Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

const Main = styled.main`
  background-color: ${p => p.theme.color.containerBg};
  padding: 1rem;

  p {
    max-width: 700px;
  }
`;

const Recruitment = () => {
  const { t } = useTranslation();
  return (
    <Main>
      {/* <h1>{t('rekry.title')}</h1>
      <p>{t('rekry.paragraph1')}</p>
      <p>{t('rekry.paragraph2')}</p>
      <p>
        <Trans
          i18nKey="rekry.paragraph3"
          components={[<a href='mailto:toimitus@radiodiodi.fi'> </a>, <a href="mailto:rekry@radiodiodi.fi"> </a>]} />
      </p> */}

      {/* <h1>Hae tuottajaksi Radiodiodiin!</h1>
      <p>
        Lorem ipsum dolor sit amet joo salee tarvitaan tuottajia
      </p>
      <p>
        Tähän lörinää siitä mitä tuottaja tekee
      </p> */}
      
      <h1>{t('rekry.closed')}</h1>
      {/* <RecruitmentForm /> */}
    </Main>
  );
}

export default Recruitment;
