import React, { useMemo } from 'react';
import styled from 'styled-components';
import i18next from '../../../i18n';
import { environment } from '../../../utils';

const { 
  REACT_APP_START_DATE,
  REACT_APP_END_DATE
} = environment;

const Container = styled.div`
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: ${p => p.theme.color.white10};
  margin-right: 0.5rem;

  @media screen and (min-width: 700px) {
    flex: 1 0 350px;
  }
`;

const Title = styled.h4`
  margin: 0;
`;

const DateContainer = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5rem;
  font-family: monospace;
  font-size: 1rem;
`;

const DateCell = styled.td`
  cursor: pointer;
  position: relative;

  &:hover:after {
    content: '';
    z-index: -1;
    position: absolute;

    height: 1.6em;
    width: 1.6em;
    border-radius: 100%;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: ${p => p.theme.color.redMedium};
  }
`;

const DateHeader = styled.td`
  user-select: none;
  border-bottom: 1px solid;
`;

const FillerDate = styled.td`
`;

const radioStartDate = new Date(`${REACT_APP_START_DATE}T00:00:00Z`);
const endDate = new Date(`${REACT_APP_END_DATE}T00:00:00Z`); // exclusive, usually 30.4. - marked with emoji

// don't show days earlier than today
const today = new Date(0);
// (and hope the user is in the correct timezone)
const now = new Date();
// use UTC for calendar generation though so timezones etc. don't matter
today.setUTCFullYear(now.getFullYear(), now.getMonth(), now.getDate());
const startDate = today > radioStartDate ? today : radioStartDate;

const CalendarWidget = ({ show, onDateSelect }) => {
  // Generate the rows of the calendar.
  const rows = useMemo(() => {
    const rows = [];
    // Fill also the day after end of broadcast with an emoji
    const afterEndDate = new Date(endDate);
    afterEndDate.setUTCDate(endDate.getUTCDate() + 1);
    for (let date = new Date(startDate); date <= afterEndDate; date.setUTCDate(date.getUTCDate() + 1)) {
      // compute column from weekday
      const col = (date.getUTCDay() + 6) % 7;
      // add new row if necessary
      if (!rows.length || col === 0) {
        rows.push(Array(7).fill().map((_, index) => <FillerDate key={index} />));
      }
      if (date >= afterEndDate) {
        // last day, unselectable and has emoji
        rows[rows.length - 1][col] = <FillerDate key={col}><span role="img" aria-label="party emoji">🥳</span></FillerDate>
      } else {
        // normal selectable day
        const dateDay = date.getUTCDate();
        rows[rows.length - 1][col] = (
          <DateCell key={col} onClick={() => onDateSelect(dateDay)}>{dateDay}</DateCell>
        );
      }
    }

    return rows.map((row, index) => <tr key={index}>{row}</tr>);
  }, [onDateSelect]);

  const t = i18next.t;

  if (!show) return null;

  return (
    <Container>
      <Title>{t('signup.propositions.april')}</Title>
      <DateContainer>
        <thead>
          <tr>
            <DateHeader>{t('signup.weekdays.mon')}</DateHeader>
            <DateHeader>{t('signup.weekdays.tue')}</DateHeader>
            <DateHeader>{t('signup.weekdays.wed')}</DateHeader>
            <DateHeader>{t('signup.weekdays.thu')}</DateHeader>
            <DateHeader>{t('signup.weekdays.fri')}</DateHeader>
            <DateHeader>{t('signup.weekdays.sat')}</DateHeader>
            <DateHeader>{t('signup.weekdays.sun')}</DateHeader>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </DateContainer>
    </Container>
  );
};

export default CalendarWidget;
