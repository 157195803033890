import React, { Component } from 'react';
import styled from 'styled-components';
import { asField } from 'informed';
import Proposition from './Proposition';
import { PlusIcon } from '../Icons';
import i18next from '../../../i18n';

const Container = styled.div`
  margin: 10px 0;
`;

const AddButton = styled.div`
  display: flex;
  align-content: end;
  cursor: pointer;

  svg {
    height: 20px;
    width: 20px;
    margin: auto 1rem;
    path {
      fill: ${p => p.theme.color.redDark};
    }
  }

  &:hover {
    color: ${p => p.theme.color.redDark};
  }
`;

class PropositionPicker extends Component {
  setPropositionValue = index => (newValue) => {
    const { fieldState, fieldApi } = this.props;
    const { setValue } = fieldApi;
    const prev = [...fieldState.value];
    prev[index] = newValue;
    setValue(prev);
  }

  deleteProposition = index => (newValue) => {
    const { fieldState, fieldApi } = this.props;
    const { setValue } = fieldApi;
    const prev = [...fieldState.value];
    prev.splice(index, 1);
    setValue(prev);
  }

  renderPropositions = () => {
    const { fieldState } = this.props;
    const value = fieldState.value || [];

    const rows = value.map((p, i) => (
      <Proposition
        onUpdateValue={this.setPropositionValue(i)}
        onDelete={this.deleteProposition(i)}
        key={i}
        {...p}
      />
    ));

    return (
      <div>
        {rows}
      </div>
    );
  }

  onAddProposition = () => {
    const { fieldState, fieldApi } = this.props;
    const { setValue } = fieldApi;
    const oldValue = fieldState.value || [];

    // remove nulls
    const value = oldValue.filter(p => !!p.date);

    setValue([
      ...value,
      { date: null, startTime: null, endTime: null },
    ]);
  }

  renderAddButton() {
    return (
      <AddButton onClick={this.onAddProposition}>
        <PlusIcon />
        <span>{i18next.t('signup.propositions.add')}</span>
      </AddButton>
    );
  }

  render() {
    return (
      <Container>
        {this.renderPropositions()}
        {this.renderAddButton()}
      </Container>
    )
  }
}

export default asField(PropositionPicker);
