import React from 'react';
import styled from 'styled-components';

const Widget = styled.select`
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  user-select: none;
  font-variant-numeric: tabular-nums;
  font-size: 1rem;
  line-height: 1.3rem;
  border-radius: 0;
  appearance: none;
`;

const leftPad = n => n >= 10 ? `${n}` : `0${n}`;

const generateOptions = () => {
  // Alkuperäiset arvot Array 24 leftPad(n)
  const times = [...Array(48).keys()].map((n, i) => {
    const hours = Math.floor(n/2);
    const minutes = n % 2 ? '30' : '00'
    const time = `${leftPad(hours)}:${minutes}`;
    return <option key={i} value={time}>{time}</option>;
  });
  return [
    <option key="--:--" value={null}>--:--</option>,
    ...times,
  ];
}

const options = generateOptions();

const TimePicker = ({ time, onChange }) => {
  return (
    <Widget onChange={onChange} value={time}>
      {options}
    </Widget>
  );
}

export default TimePicker;
